import * as yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack";
import PasswordOTP from "./PasswordOTP";
import { useForm } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoModal from "../../components/Modals/InfoModal";
import { passwordResetService } from "../../api/passwordReset";
import ButtonGroup from "../../components/forms/ButtonGroup/ButtonGroup";

const schema = yup
  .object({
    username: yup.string().required("Username is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
  })
  .required();

const ForgotPassword = ({ open, handleClose }) => {
  const [otpModal, setOtpModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    username: "",
    email: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const { mutateAsync: handleFormSubmit, isPending } = useMutation({
    mutationFn: passwordResetService.forgotPassword,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
    },
  });

  const onSubmit = (data) => {
    reset();
    handleClose();
    handleFormSubmit({
      ERPUserName: data.username,
      ERPUserMail: data.email,
    });
    setOtpModal(true);
  };

  return (
    <>
      <InfoModal
        open={open}
        handleClose={handleClose}
        title="Password Reset Request"
        width="26rem"
        content={
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" gap={1.2} padding={1.2}>
              <TextField
                fullWidth
                label="Enter your System UserName"
                variant="outlined"
                size="small"
                {...register("username")}
                error={!!errors.username}
                helperText={errors.username?.message}
              />
              <TextField
                fullWidth
                label="Enter your associated Email address"
                variant="outlined"
                size="small"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <ButtonGroup
                onClickClose={handleClose}
                saveLabel="Request"
                isPending={isPending}
              />
            </Box>
          </form>
        }
      />
      <PasswordOTP open={otpModal} handleClose={() => setOtpModal(false)} />
    </>
  );
};

export default ForgotPassword;

import dayjs from "dayjs";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "../../../network/Axios";
import { useTranslation } from "react-i18next";
import { Tooltip, Box } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import Image from "@mui/icons-material/Image";
import ChipStatus from "../../../components/ChipStatus";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import useImagePopup from "../../../hooks/useImagePopup";
import ImageModal from "../../../components/Modals/ImageModal";
import AddInvoices from "./InfoModals/AddInvoices";
import { generateId } from "../../../utils/globalFunctions";
import Spinner from "../../../components/Spinners/Spinner";
import InfoCard from "../../../components/InfoPagesCard/InfoCard";
import InfoModal from "../../../components/Modals/InfoModal";
import FormsDataGrid from "../../../components/DataGrid/FormsDataGrid";
import InvoicesSnapShot from "../ClientInvoices/InfoModals/InvoicesSnapShot";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ClientPurchaseDetailsCard from "./InfoModals/ClientPurchaseDetailsCard";
import ConfirmationInfoModal from "../../../components/Modals/ConfirmationInfoModal";

export function DetailPanelContent({ row: rowProp }) {
  const [t] = useTranslation("global");
  const [d6Modal, setD6Modal] = useState(false);
  const [d6RefNo, setD6RefNo] = useState(null);

  const { data: invoiceDetails, isLoading: loadDetails } = useQuery({
    queryKey: ["clientPurchaseDetails", rowProp],
    queryFn: async () => {
      const response = await Axios.get("/ClientInvoices", {
        params: {
          SelectedNo: rowProp,
          State: "CardDetailed",
        },
      });
      return response.data.Acceptance;
    },
    enabled: !!rowProp,
  });

  const fetchData = async (id) => {
    try {
      const response = await Axios.get("/Quotations", {
        params: {
          SelectedNo: id,
          State: "Document",
        },
      });
      return response.data.result;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    isImageLoading,
    isImageModalOpen,
    image,
    handleImageModalOpen,
    handleCloseImageModal,
  } = useImagePopup(fetchData, "AcceptanceAttachement");

  const invoicesColumns = [
    {
      field: "WorkOrderD6No",
      headerName: t("data_grid.headers.ref_no"),
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            style={{
              color: "#1790FF",
            }}
            onClick={() => {
              setD6RefNo(params.value);
              setD6Modal(true);
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "WorkOrderNo",
      headerName: t("data_grid.headers.order_no"),
      width: 130,
      renderCell: (params) => (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${params.value}`}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "SiteName",
      headerName: t("data_grid.headers.site_name"),
      width: 150,
      flex: 1,
    },
    {
      field: "SiteCode",
      headerName: t("data_grid.headers.site_code"),
      width: 130,
    },
    {
      field: "STotal",
      headerName: t("data_grid.headers.supply"),
      width: 110,
    },
    {
      field: "ITotal",
      headerName: t("data_grid.headers.install"),
      width: 110,
    },
    {
      field: "WorkOrderD6Status",
      headerName: t("data_grid.headers.status"),
      width: 110,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
    {
      field: "Actions",
      type: "actions",
      width: 90,
      headerName: t("data_grid.headers.actions"),
      getActions: (params) => [
        <Tooltip title="View" key="image">
          <GridActionsCellItem
            icon={<Image />}
            label="Image"
            onClick={() => {
              handleImageModalOpen(params.row.WorkOrderD6No);
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <FormsDataGrid
        rows={invoiceDetails || []}
        columns={invoicesColumns}
        loading={loadDetails}
        height="auto"
        getRowId={generateId()}
        hideFooter
      />
      <ImageModal
        open={isImageModalOpen}
        handleClose={handleCloseImageModal}
        image={image}
        loading={isImageLoading}
        title={`${rowProp}`}
      />
      <InfoModal
        title={`${t(
          "info_modules.work_orders.description.quotation_details"
        )} - ${d6RefNo}`}
        content={<InvoicesSnapShot d6RefNo={d6RefNo} open={d6Modal} />}
        handleClose={() => setD6Modal(false)}
        open={d6Modal}
        width="64rem"
      />
    </>
  );
}

const ClientPurchaseOrderInfo = () => {
  const { clientPurchaseId } = useParams();
  const queryClient = useQueryClient();
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [isEditable, setIsEditable] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addInvoiceModal, setAddInvoiceModal] = useState(false);

  const getDetailPanelContent = ({ row }) => (
    <DetailPanelContent row={row.InvoiceNo} />
  );

  const getActionType = (modalType) => {
    switch (modalType) {
      case "confirmClosePo":
        return "close";
      case "confirmReopenPo":
        return "reopen";
      default:
        return null;
    }
  };

  const handleOpenModal = (modalType) => {
    switch (modalType) {
      case "addInvoices":
        setAddInvoiceModal(true);
        break;
      case "confirmClosePo":
      case "confirmReopenPo":
        setActionType(getActionType(modalType));
        setConfirmModal(true);
        break;
      default:
        break;
    }
  };

  const { data: clientPurchaseInfo, isLoading } = useQuery({
    queryKey: ["clientPurchase", "info", clientPurchaseId],
    queryFn: async () => {
      const response = await Axios.get("/ClientPurchaseOrders", {
        params: {
          SelectedNo: clientPurchaseId,
          State: "CardDetailed",
        },
      });
      return response.data;
    },
  });

  const usePurchaseMutation = (State, successMessage) => {
    const { mutateAsync, isPending } = useMutation({
      mutationFn: async () => {
        return Axios.patch("/ClientPurchaseOrders", {
          SelectedNo: clientPurchaseId,
          State: State,
        });
      },
      onSuccess: (data) => {
        if (!data) return;
        enqueueSnackbar(successMessage, {
          variant: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["clientPurchase", "info"],
        });
      },
    });

    return { mutateAsync, isPending };
  };

  const { mutateAsync: handleClose, isPending: pendingClose } =
    usePurchaseMutation("Close", "Client Purchase Order Closed Successfully");

  const { mutateAsync: handleReopen, isPending: pendingReopen } =
    usePurchaseMutation("Open", "Client Purchase Order Opened Successfully");
  const columns = [
    {
      field: "InvoiceNo",
      headerName: t("data_grid.headers.ref_no"),
      width: 120,
    },
    {
      field: "GovernmentInvoice",
      headerName: t("data_grid.headers.gov_invoice"),
      flex: 1,
    },
    {
      field: "Activity",
      headerName: t("data_grid.headers.activity"),
      flex: 1,
    },
    {
      field: "CreationDate",
      headerName: t("data_grid.headers.creation_date"),
      width: 110,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "SubmissionDate",
      headerName: t("data_grid.headers.submission_date"),
      width: 105,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoiceDate",
      headerName: t("data_grid.headers.invoice_date"),
      width: 105,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "D6Date",
      headerName: t("data_grid.headers.d6_date"),
      width: 110,
      renderCell: ({ value }) => {
        return value ? dayjs(value).format("YYYY/MM/DD") : "";
      },
    },
    {
      field: "InvoicesAmount",
      headerName: t("data_grid.headers.amount"),
      flex: 1,
    },
    {
      field: "InvoiceType",
      headerName: t("data_grid.headers.type"),
      width: 70,
    },
    {
      field: "InvoiceStatus",
      headerName: t("data_grid.headers.status"),
      width: 110,
      renderCell: ({ value }) => {
        return <ChipStatus value={value} />;
      },
    },
  ];
  const actionTypeMap = {
    close: {
      onClick: handleClose,
      description: t("info_modules.client_purchase.confirmation.close"),
      isPending: pendingClose,
    },
    reopen: {
      onClick: handleReopen,
      description: t("info_modules.client_purchase.confirmation.reopen"),
      isPending: pendingReopen,
    },
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={1.7} marginBottom={2}>
            <InfoCard
              title={t(
                "info_modules.client_purchase.description.client_purchase_details"
              )}
              editButton={clientPurchaseInfo?.Actions.includes("Edit")}
              onClickEdit={() => setIsEditable(true)}
              content={
                <ClientPurchaseDetailsCard
                  clientPurchaseInfo={clientPurchaseInfo}
                  handleOpenModal={handleOpenModal}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                />
              }
            />
            <InfoCard
              title={t("info_modules.client_purchase.description.invoices")}
              content={
                <FormsDataGrid
                  rows={clientPurchaseInfo?.invoices || []}
                  columns={columns}
                  height={520}
                  getRowId={(row) => row.InvoiceNo}
                  toolbar
                  addInvoiceBtn={clientPurchaseInfo?.Actions.includes(
                    "AddInvoices"
                  )}
                  addInvoiceFn={() => handleOpenModal("addInvoices")}
                  showExportButton
                  getDetailPanelHeight={() => "auto"}
                  getDetailPanelContent={getDetailPanelContent}
                />
              }
            />
          </Box>
          <ConfirmationInfoModal
            open={confirmModal}
            handleClose={() => setConfirmModal(false)}
            onClick={actionTypeMap[actionType]?.onClick}
            description={actionTypeMap[actionType]?.description}
            isPending={actionTypeMap[actionType]?.isPending}
            color="error"
          />
          <AddInvoices
            open={addInvoiceModal}
            handleClose={() => setAddInvoiceModal(false)}
          />
        </>
      )}
    </>
  );
};
export default ClientPurchaseOrderInfo;

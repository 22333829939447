import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import AxiosRefresh from "../../network/AxiosRefresh";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTokens } from "../../store/slices/authSlice";
import { jwtDecode } from "jwt-decode";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  styled,
  Typography,
  Link,
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import "./Login.css";
import {
  setColumnsConfig,
  setInitialTheme,
} from "../../store/slices/themeSlice";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import Axios from "../../network/Axios";
import ForgotPassword from "./ForgotPassword";
import ChangePasswordForm from "../../components/forms/ChangePasswordForm";

const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const { state } = useLocation();
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const { language } = useSelector((state) => state.theme); // light | dark

  const [t] = useTranslation("global");

  const userSchema = yup.object().shape({
    username: yup.string().required(t("login_page.required")),
    password: yup.string().required(t("login_page.required")),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await AxiosRefresh.post("/UserLogin", {
        UserName: values.username,
        Password: values.password,
      });
      if (response.status === 200) {
        const accessToken = response.data.token;

        // Grouping the data by ModulesCategoryName using reduce
        const tokenDecoded = jwtDecode(accessToken);
        const UserPrivTable = tokenDecoded.UserPriv;
        const refreshToken = response.data.refreshToken;
        const DarkMode = tokenDecoded.DarkMode;
        const Language = tokenDecoded.Language;
        const NickName = tokenDecoded.UserNickName;
        const passwordReset = response.data.PasswordReset;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userPriv", JSON.stringify(UserPrivTable));
        localStorage.setItem("NickName", NickName);
        localStorage.setItem("DarkMode", JSON.stringify(DarkMode));
        localStorage.setItem("Language", JSON.stringify(Language));

        dispatch(setInitialTheme({ DarkMode, Language }));

        dispatch(
          setTokens({
            accessToken,
            refreshToken,
            userPriv: UserPrivTable,
            nickName: NickName,
          })
        );

        // Get columns visibility model
        const columnsConfig = await Axios.get("/UsersManagement", {
          params: { State: "ColumnsConfig" },
        });

        const parsedColumnsConfig = JSON.parse(
          columnsConfig.data.result[0].ColumnsConfig
        );

        localStorage.setItem("ColumnsConfig", columnsConfig);

        dispatch(setColumnsConfig(parsedColumnsConfig));
        if (passwordReset) {
          setResetPasswordModal(true);
        } else {
          navigate(state?.from || "/", { replace: true });
        }
        setErrorMessage(`An error occurred: ${response.status}`);
      }
    } catch (error) {
      if (!error.response) {
        // The request was made but no response was received
        console.error("Error logging in:", error);
        setErrorMessage("Server Connection Down");
      } else if (error.response.status === 400) {
        console.error("Error logging in:", error);
        setErrorMessage(error.response.data.msg);
      } else {
        setErrorMessage(`An error occurred: ${error.response.status}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const CustomTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "3px solid #E0E3E7",
      },
      "&:hover fieldset": {
        borderColor: "#B2BAC2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6F7E8C",
      },
      input: {
        paddingLeft: "9px",
      },
    },
  });
  const ErrorFallback = () => setErrorMessage("Server is Down");

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="login-container">
        <div className="grid-2">
          <div style={{ textAlign: "center" }}>
            <p className="intro-message">
              <span style={{ fontWeight: 500 }}>Access</span> pathway to
              increased productivity
              <br />
              Business excellence with each keystroke
              <br />
              Unlocking doors to success
            </p>
            <img src="/login7.png" alt="for design" className="design-img" />
          </div>
          <div className="login-form">
            <div style={{ textAlign: "center" }}>
              <img
                src="/GP Logo Web (Light).png"
                alt="login-logo"
                className="logo"
              />
            </div>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={userSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form className="form" onSubmit={handleSubmit}>
                  <div className="input ">
                    <div>
                      <Typography pb={1} className="label">
                        {t("login_page.username")}
                      </Typography>
                      <CustomTextField
                        placeholder={t("login_page.username_placeholder")}
                        name="username"
                        value={values.username}
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="input-field"
                        error={!!touched.username && !!errors.username}
                        helperText={touched.username && errors.username}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="icon">
                              <PersonOutlineOutlinedIcon
                                style={{ color: "#504b6c" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:focus-within fieldset": {
                              borderColor: "#888692",
                            },
                          },
                          "& label.Mui-focused": {
                            color: "#4ea1be",
                          },
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                    </div>
                    <div>
                      <Typography pb={1} className="label">
                        {t("login_page.password")}
                      </Typography>
                      <CustomTextField
                        placeholder={t("login_page.password_placeholder")}
                        name="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="input-field"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" className="icon">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffOutlinedIcon
                                    style={{ color: "#504b6c" }}
                                  />
                                ) : (
                                  <VisibilityOutlinedIcon
                                    style={{ color: "#504b6c" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <InputAdornment position="start" className="icon">
                              <LockOutlinedIcon style={{ color: "#504b6c" }} />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:focus-within fieldset": {
                              borderColor: "#888692",
                            },
                          },
                          "& label.Mui-focused": {
                            color: "#4ea1be",
                          },
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                      <Link
                        onClick={() => setForgetPasswordModal(true)}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingTop: "5px",
                          fontSize: "14px",
                          fontFamily: "inherit",
                          cursor: "pointer",
                          color: "inherit",
                          textDecorationColor: "inherit",
                          "&:hover": {
                            color: "#787f85",
                            textDecorationColor: "#787f85",
                          },
                        }}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="error-message">
                    {errorMessage && (
                      <p className="error-text">{errorMessage}</p>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    endIcon={
                      loading ? undefined : language === "AR" ? (
                        <ArrowCircleLeftIcon style={{ color: "white" }} />
                      ) : (
                        <ArrowCircleRightIcon style={{ color: "white" }} />
                      )
                    }
                    disabled={loading}
                    className="btn-login"
                    sx={{
                      padding: "6px 0",
                      fontSize: "120%",
                      display: "flex",
                      alignItems: "center",
                      background: "#403d50",
                      "&:hover": {
                        background: "#514e69",
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "#fff",
                        }}
                      />
                    ) : (
                      t("login_page.login_button")
                    )}
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <ForgotPassword
          open={forgetPasswordModal}
          handleClose={() => setForgetPasswordModal(false)}
        />
        <ChangePasswordForm
          open={resetPasswordModal}
          handleClose={() => setResetPasswordModal(false)}
        />
      </div>
    </ErrorBoundary>
  );
};

export default Login;

import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChipStatus from "../../../../components/ChipStatus";
import ChipComponent from "../../../../components/Chips/ChipComponent";

const WorkOrderCard = ({ missionInfo }) => {
  const [t] = useTranslation("global");

  const {
    SiteName,
    SiteCode,
    SiteAddress,
    GovernoratesName,
    Latitude,
    Longitude,
    idSitesDatabase,
  } = missionInfo?.WorkOrders[0];
  const {
    Status,
    ERPUserNickName,

    RequestedFor,
  } = missionInfo?.result[0];

  const missionProperty = [
    {
      label: t("data_grid.headers.work_order_no"),
      value: (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/workorders/${missionInfo?.WorkOrders[0]?.WorkOrderNo}`}
          target="_blank"
        >
          {missionInfo?.WorkOrders[0]?.WorkOrderNo}
        </Link>
      ),
    },
    {
      label: t("data_grid.headers.status"),
      value: <ChipStatus value={Status} />,
    },
    {
      label: t("data_grid.headers.user"),
      value: ERPUserNickName,
    },
    {
      label: t("data_grid.headers.site_name"),
      value: (
        <Link
          style={{
            color: "#1790FF",
          }}
          to={`/sitesdatabase/${SiteName}?id=${idSitesDatabase}`}
          target="_blank"
        >
          {SiteName}
        </Link>
      ),
    },
    {
      label: t("data_grid.headers.site_code"),
      value: SiteCode,
    },
    {
      label: t("data_grid.headers.site_address"),
      value: SiteAddress,
    },

    {
      label: t("data_grid.headers.area"),
      value: GovernoratesName,
    },

    {
      label: t("data_grid.headers.latitude"),
      value: Latitude,
    },
    {
      label: t("data_grid.headers.longitude"),
      value: Longitude,
    },
    {
      label: t("data_grid.headers.requested_for"),
      value: RequestedFor,
    },
  ];
  return (
    <Box>
      <Grid container>
        {missionProperty.map((property, index) => (
          <Grid item xs={4} key={index} marginBottom={0.5}>
            <ChipComponent label={property.label} value={property.value} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ChipComponent
            label={t("data_grid.headers.wo_description")}
            value={missionInfo?.WorkOrders[0].WorkOrderDiscription}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default WorkOrderCard;

import * as yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoModal from "../../components/Modals/InfoModal";
import { passwordResetService } from "../../api/passwordReset";
import ButtonGroup from "../../components/forms/ButtonGroup/ButtonGroup";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import {
  TextField,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

const schema = yup.object({
  otp: yup.string().required("OTP is required"),
  userName: yup.string().required("Username is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(/[A-Z]/, "Must contain an uppercase letter")
    .matches(/[a-z]/, "Must contain a lowercase letter")
    .matches(/[0-9]/, "Must contain a number")
    .matches(/[~!@#$%^&*?/]/, "Must contain a special character"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const PasswordOTP = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPasswordVisibility = () => setShowNewPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  const initialValues = {
    otp: "",
    userName: "",
    newPassword: "",
    confirmPassword: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const { mutateAsync: handleFormSubmit, isPending } = useMutation({
    mutationFn: passwordResetService.resetPassword,
    onSuccess: (data) => {
      handleClose();
      enqueueSnackbar(data.msg, { variant: "success" });
    },
  });

  const onSubmit = (data) => {
    reset();
    handleClose();
    handleFormSubmit({
      OTP: data.otp,
      Password: data.newPassword,
      ERPUserName: data.userName,
    });
  };
  const handleCloseSave = () => {
    reset();
    handleClose();
  };

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title="Password Reset"
      width="30rem"
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "16px",
            }}
          >
            <TextField
              fullWidth
              label="Enter the OTP sent to your email"
              variant="outlined"
              size="small"
              {...register("otp")}
              error={!!errors.otp}
              helperText={errors.otp?.message}
            />
            <TextField
              fullWidth
              label="Enter your username"
              variant="outlined"
              size="small"
              {...register("userName")}
              error={!!errors.userName}
              helperText={errors.userName?.message}
            />
            <TextField
              fullWidth
              label="Enter your new password"
              variant="outlined"
              size="small"
              type={showNewPassword ? "text" : "password"}
              {...register("newPassword")}
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleNewPasswordVisibility}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <VisibilityOffOutlined color="grey" />
                      ) : (
                        <VisibilityOutlined color="grey" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Confirm your new password"
              variant="outlined"
              size="small"
              {...register("confirmPassword")}
              type={showConfirmPassword ? "text" : "password"}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlined color="grey" />
                      ) : (
                        <VisibilityOutlined color="grey" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                {t("change_password_form.tips.headline")}
              </Typography>
              <ul
                style={{
                  padding: "0px 25px",
                  fontSize: "15px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <li>{t("change_password_form.tips.uppercase")}</li>
                <li>{t("change_password_form.tips.lowercase")}</li>
                <li>{t("change_password_form.tips.numbers")}</li>
                <li>{t("change_password_form.tips.special_characters")}</li>
              </ul>
            </Box>
            <ButtonGroup
              onClickClose={handleCloseSave}
              saveLabel="Reset"
              isPending={isPending}
            />
          </Box>
        </form>
      }
    />
  );
};

export default PasswordOTP;

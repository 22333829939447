import { useState } from "react";
import PasswordStrengthBar from "./PasswordStrengthBar";
import PasswordField from "./PasswordField";
import * as yup from "yup";
import Axios from "../../network/Axios";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import ButtonGroup from "./ButtonGroup/ButtonGroup";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import InfoModal from "../Modals/InfoModal";

const ChangePasswordForm = ({ open, handleClose }) => {
  const [t] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [strength, setStrength] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const passwordSchema = yup.object().shape({
    oldPassword: yup.string().required(t("change_password_form.required")),
    newPassword: yup.string().required(t("change_password_form.required")),
    confirmPassword: yup
      .string()
      .required(t("change_password_form.required"))
      .test(
        "passwords-match",
        t("change_password_form.passwords_match"),
        function (value, { parent }) {
          return value === parent.newPassword;
        }
      ),
  });

  const handleCloseSave = () => {
    resetForm();
    handleClose();
    setStrength("");
  };

  const { mutateAsync: handleFormSubmit, isPending } = useMutation({
    mutationFn: async (values) => {
      return Axios.patch("/ChangePassword", {
        NewPassword: values.newPassword,
        Password: values.oldPassword,
      });
    },
    onSuccess: () => {
      handleCloseSave();
      enqueueSnackbar("Password Changed Successfully", { variant: "success" });
      navigate("/login");
    },
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: handleFormSubmit,
  });

  const { mutateAsync: getPasswordStrength } = useMutation({
    mutationFn: async (password) => {
      const response = await Axios.get("/PasswordStrengthCheck", {
        params: {
          NewPassword: `"${password}"`,
        },
      });
      return response.data?.msg;
    },
    onSuccess: (strength) => {
      switch (strength) {
        case "Too weak":
          setStrength("too weak");
          break;
        case "Weak":
          setStrength("weak");
          break;
        case "Medium":
          setStrength("medium");
          break;
        case "Strong":
          setStrength("strong");
          break;
        default:
          setStrength("too weak");
      }
    },
  });
  const handleConfirmChange = (event, values) => {
    const confirmValue = event.target.value;
    setConfirmError(
      values.newPassword !== confirmValue ? "Passwords must match" : ""
    );
  };

  return (
    <InfoModal
      open={open}
      handleClose={handleCloseSave}
      title={t("navbar.account_menu.change_password")}
      width="32rem"
      content={
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap="12px" padding={2}>
            <PasswordField
              label={t("change_password_form.old_password")}
              name="oldPassword"
              value={values.oldPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!touched.oldPassword && !!errors.oldPassword}
              helperText={touched.oldPassword && errors.oldPassword}
            />
            <PasswordField
              label={t("change_password_form.new_password")}
              name="newPassword"
              value={values.newPassword}
              onBlur={handleBlur}
              onChange={(event) => {
                const newPassword = event.target.value;
                handleChange(event);
                getPasswordStrength(newPassword);
                handleConfirmChange(event, values);
              }}
              error={!!touched.newPassword && !!errors.newPassword}
              helperText={touched.newPassword && errors.newPassword}
            />
            <PasswordStrengthBar strength={strength} />
            <PasswordField
              label={t("change_password_form.confirm_password")}
              name="confirmPassword"
              value={values.confirmPassword}
              onBlur={handleBlur}
              onChange={(event) => {
                handleChange(event);
                handleConfirmChange(event, values);
              }}
              error={
                !!touched.confirmPassword &&
                (!!errors.confirmPassword || !!confirmError)
              }
              helperText={
                touched.confirmPassword &&
                (errors.confirmPassword || confirmError)
              }
            />
            <Box>
              <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                {t("change_password_form.tips.headline")}
              </Typography>

              <ul
                style={{
                  padding: "0px 25px",
                  fontSize: "15px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <li> {t("change_password_form.tips.uppercase")}</li>
                <li>{t("change_password_form.tips.lowercase")}</li>
                <li> {t("change_password_form.tips.numbers")}</li>
                <li>{t("change_password_form.tips.special_characters")}</li>
              </ul>
            </Box>
          </Box>
          <ButtonGroup
            onClickClose={handleCloseSave}
            saveLabel={t("modules.buttons.save")}
          />
        </form>
      }
    />
  );
};

export default ChangePasswordForm;

import Axios from "../network/Axios";

const forgotPassword = async (params) => {
  const response = await Axios.get("/ForgotPassword", { params });
  return response.data;
};

const resetPassword = async (data) => {
  const response = await Axios.put("/ResetPassword", data);
  return response.data;
};

export const passwordResetService = {
  forgotPassword,
  resetPassword,
};
